import { HELLO_PATH, ROOT_PATH } from 'shared/consts';
import { Route, Routes } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { AppRoot } from 'components/app-root';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { HelloReactPage } from 'pages/hello-react';
import { MiniappSdkDemoPage } from 'pages/miniapp-sdk-demo';
import { Private } from 'components/router/private';
import React from 'react';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path={ROOT_PATH} element={<Private><HelloReactPage /></Private>} />
      <Route path={HELLO_PATH} element={<AppRoot />} />
      <Route path="/sdk-demo" element={<MiniappSdkDemoPage />} />
      <Route path="/" element={<AppRoot />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route element={<DefaultRedirect />} />
    </Routes>
  </AppContentWrapper>
);
