import { Container } from 'hocs/shared-styles';
import { Navigate } from 'react-router-dom';
import React from 'react';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { hasToken } from 'store/user/selectors';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useSelector } from 'react-redux';

interface PrivateProps {
  redirectTo?: string;
  children: React.ReactNode;
}
export const Private: React.FC<PrivateProps> = ({
  redirectTo = '/error', children,
}: PrivateProps): JSX.Element | null => {
  const [currentUser, loading] = useCurrentUser();
  const isTokenStored = useSelector(hasToken);

  if (currentUser) {
    // Wrapped in a React Fragment intentionally
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  if (!isTokenStored && !loading) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <Container isLoading>
      <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
    </Container>
  );
};
