import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { ErrorBoundary, init as initSentry } from '@sentry/react';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import { App } from 'components/app';
import { ErrorPage } from 'pages/error';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { Integrations } from '@sentry/tracing';
import { IntlProvider } from 'components/intl-provider';
import LaunchDarklyInitializer from 'components/launch-darkly/LaunchDarklyInitializer';
import { MiniappSdkProvider } from 'components/miniapp-sdk-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
// https://reactrouter.com/docs/en/v6/api#unstable_historyrouter
// This API is currently prefixed as unstable_ because you may unintentionally add two versions of the history
// library to your app, the one you have added to your package.json and whatever version React Router uses internally.
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { history } from 'store/router/history';
import { store } from 'store';
import { library } from '@hqo/react-components-library/dist/tenant-web/elements/theme/icons/svgCore';

library.add(faExclamationTriangle);

initSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <MiniappSdkProvider>
      <LaunchDarklyInitializer>
        <IntlProvider>
          <HqoThemeProvider>
            <AppThemeProvider theme={defaultTheme}>
              <ErrorBoundary fallback={<ErrorPage />}>
                <Router history={history}>
                  <App />
                </Router>
              </ErrorBoundary>
            </AppThemeProvider>
          </HqoThemeProvider>
        </IntlProvider>
      </LaunchDarklyInitializer>
    </MiniappSdkProvider>
  </ReduxProvider>
);
