import React from 'react';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { Navigate } from 'react-router-dom';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const [user, loading] = useCurrentUser();

  if (user || loading) {
    return <Navigate to="/hello" />;
  }

  return <Navigate to="/error" />;
};
