import { Navigate, generatePath } from 'react-router';

import { ROOT_PATH } from 'shared/consts';
import React from 'react';
import { useViewParams } from 'hooks/use-view-params.hook';

export const AppRoot = (): JSX.Element => {
  const params = useViewParams();

  if (!params.buildingUuid) {
    return <Navigate to="/error" />;
  }

  const targetUrl = `${generatePath(ROOT_PATH, { ownerId: params.buildingUuid, ownerType: 'building' })}`;

  return <Navigate to={targetUrl} />;
};
