import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';

import { SwitchAltBuildingModal } from '../../components/alt-buildings-modal/alt-building-modal';
import { useAltBuildings } from 'hooks/alt-building.hook';
import { useViewParams } from 'hooks/use-view-params.hook';
import { getAltBuildings } from '../../store/alt-buildings/actions';
import { useDispatch } from 'react-redux';
import { useThemeFont } from 'hooks/use-theme-font.hook';
import { FontLocation } from 'shared/consts';

export const HelloReactPage = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const altBuildings = useAltBuildings();
  const { buildingUuid } = useViewParams();
  const themeFontTitle = useThemeFont(FontLocation.TITLE);

  const [visible, setVisible] = useState(true);
  const onClose = useCallback(() => {
    setVisible(false);
    dispatch(getAltBuildings.request(buildingUuid));
  }, []);

  return (
    <>
      <PageTitle data-cy="hello-react" font={themeFontTitle}>
        {intl.formatMessage({ id: 'hello_react' })}
      </PageTitle>
      {altBuildings && <SwitchAltBuildingModal altBuildings={altBuildings} isVisible={visible} onClose={onClose} />}
    </>
  );
};
